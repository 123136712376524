<template>
  <div class="wwa_box main">
    <div class="banner_box">
      <div class="swiper_box">
        <img src="@/assets/images/banner4.png" />
      </div>
    </div>
    <div class="container content">
      <ul class="list_">
        <li>
          <div class="logo"><img src="@/assets/images/wwa_logo3.jpg" /></div>

          <div class="con">
            <div class="title_">ECI Festival</div>
            <div class="text_">
              ECI Festival is the celebration of digital innovation from around
              the world gathered in China. It is a joint effort by ECI awards
              from the 27 countries and regions. This event has been known as
              one of the top four global festivals together with CES, MWC and
              IFA. ECI Festival is held in the fourth quarter of each year. With
              “Bring Innovation to Life” as its core value, it integrates
              creativity, entrepreneurship, venture capital and innovation into
              a single event to be celebrated with the audience from the world’s
              most valuable brands and agencies, prominent technology
              representatives, marketing gurus, and investors. Each year it
              attracts over 1,000 industry experts and close to 2,000 companies
              from all the regions of the world. Event includes our influential
              discussion forums, annual award ceremony, innovation launch
              parties, networking, themed expos, innovation camps, and more.
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Introduction',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/whoweare.scss';
</style>
